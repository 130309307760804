
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import AddComma from "../../models/MoneyFormat/AddComma";
import { validateNum } from "@/models/Validator/Validator";

@Component
export default class FormSelect extends Vue {
  @Prop() id: string;
  @Prop({ default: "text" }) type!: string;
  @Prop() label?: string;
  @Prop() index?: number;
  @Prop() maxlength?: number;
  @Prop() inputData?: string | number;
  @Prop() inputVariable: string;
  @Prop() placeholder: string;
  

  addComma = AddComma;
  item: any = "";
  data: any;
  renderInput = 0;
  lock = false;

  mounted(): void {
    if (this.inputData) {
      this.item = this.inputData;
    }
  }
  onCompositionStart():void {
    this.lock = true;
  }

  onCompositionEnd(e: any):void {
    this.lock = false;
    this.handleInput(e.srcElement._value);
  }

  handleInput(newValue: any):void {
    if (this.lock) return;

    if (
      this.inputVariable === "quantity" ||
      this.inputVariable === "unitPrice"
    ) {
      //鍵入的第一個數字為0，則刪除
      if (newValue.toString().substr(0, 1) == 0) {
        newValue = newValue.toString().substr(1);   
      }

      //千分位符號拿掉
      newValue = newValue.toString().replace(/,/g, "");

      //若有文字要刪除，並重新渲染input
      if (!validateNum(newValue as string)) {
        newValue = newValue.toString().replace(/[^0-9]/g, "");
        this.renderInput++;
      }

      this.data = newValue;
      this.item = this.addComma(newValue);  
    } else {
      this.data = this.item;
    }

    this.$emit("getInput", [
      this.inputVariable,
      this.data,      
      this.index,
    ]);
   
  }

  //Watch
  @Watch("reset")
  resetHandler():void {
    this.item = this.placeholder;
  }

  @Watch("item")
  watchQuantity(newValue: any):void {
    if (this.lock == false) {
      this.handleInput(newValue);
    }
  }
}
