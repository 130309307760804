
import { Component, Vue, Ref } from "vue-property-decorator";
import * as DI from "@/utility/DependencyInjector";

@Component
export default class QuotationHeader extends Vue {
  @Ref()
  LogoutIcon!: HTMLElement;
  userImg = "";
  userName = DI.get("App").auth.currentUser
    ? DI.get("App").auth.currentUser.data.name
    : "請登入";
  open = false;
  errorImg = false;
  
  // hook
  mounted(): void {
    this.$store.commit("setUser", DI.get("App").auth.currentUser.data);
    this.userImg = this.$store.state.user.headShotPath;
  }

  // methods
  clickUser(): void {
    this.open = !this.open;
  }

  handleImgError(): void {
    this.errorImg = true;
  }

  logoutClick(): void {
    DI.get("App").auth.logout();
    localStorage.removeItem("userState");
    this.$store.commit("LoginState", false);
    this.$router.push({
      path: "/",
    });
  }
  refreshPage() {
      if(this.$route.path=='/Quotation/List'){
         this.$router.go(0);
      }
      
    }
}
