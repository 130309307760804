
import { Component, Vue, Watch } from "vue-property-decorator";
import * as DI from "@/utility/DependencyInjector";
import QuotationHeader from "@/components/Quotation/QuotationHeader.vue";
import FormSelect from "../../components/Utility/FormSelect.vue";
import AddComma from "../../models/MoneyFormat/AddComma";
import FormInput from "../../components/Utility/FormInput.vue";
import HintModal from "@/components/Modal/Util/HintModal.vue";
import Swal from "sweetalert2";
import { format, toDate } from "date-fns";

@Component({
  components: {
    FormSelect,
    FormInput,
    QuotationHeader,
    HintModal,
  },
})
export default class QuotationStandard extends Vue {
  // data
  Swal = require("sweetalert2");
  addComma = AddComma;

  isLoading = true;
  isLoadingSave = false;
  isLoadingRead = true;
  btnDisableRead = false;
  openReadBox = false;
  selectedRead = "null";
  readAllData = [];
  readSubmittedData = [];
  readTempData = [];
  fileName = "";

  vectorArray = [true, true, true, true, true, true, true];
  vectorCount = 0;

  checkSession = [true, true];
  checkSessionWord = true;
  expectElapsedTime = "";
  expectElapsedDay = "";
  description = "";
  name = "";
  quantity: any = "";
  unitPrice: any = "";
  note = "";
  checkState = {
    name: false,
    quantity: false,
    unitPrice: false,
    unit: false,
  };

  data = {
    name: "",
    quantity: 0,
    unitPrice: 0,
    unit: "",
    note: "",
  };

  options = [
    { text: "式" },
    { text: "坪" },
    { text: "尺" },
    { text: "公分" },
    { text: "台" },
    { text: "個" },
    { text: "組" },
    { text: "座" },
    { text: "扇" },
    { text: "碼" },
    { text: "米" },
    { text: "噸" },
    { text: "才" },
    { text: "片" },
    { text: "車" },
    { text: "條" },
    { text: "盞" },
    { text: "樘" },
    { text: "米平方" },
    { text: "桶" },
    { text: "包" },
    { text: "迴" },
    { text: "口" },
    { text: "工" },
  ];

  lists = [
    { title: "假設工程", totalPrice: 0, description: "" },
    { title: "拆除工程", totalPrice: 0, description: "" },
    {
      title: "場地工程",
      totalPrice: 0,
      items: [
        { text: "玄關", title: "玄關工程", subtotal: 0, description: "" },
        { text: "客廳", title: "客廳工程", subtotal: 0, description: "" },
        { text: "廚房", title: "廚房工程", subtotal: 0, description: "" },
        { text: "房間", title: "房間工程", subtotal: 0, description: "" },
        { text: "浴室", title: "浴室工程", subtotal: 0, description: "" },
        { text: "前陽台", title: "前陽台工程", subtotal: 0, description: "" },
        { text: "後陽台", title: "後陽台工程", subtotal: 0, description: "" },
        { text: "屋頂", title: "屋頂工程", subtotal: 0, description: "" },
        { text: "地下室", title: "地下室工程", subtotal: 0, description: "" },
        { text: "外牆", title: "外牆工程", subtotal: 0, description: "" },
      ],
    },
    {
      title: "工種工程",
      totalPrice: 0,
      items: [
        { text: "水電", title: "水電工程", subtotal: 0, description: "" },
        { text: "泥作", title: "泥作工程", subtotal: 0, description: "" },
        { text: "木作", title: "木作工程", subtotal: 0, description: "" },
        { text: "鋁門窗", title: "鋁門窗工程", subtotal: 0, description: "" },
        { text: "油漆", title: "油漆工程", subtotal: 0, description: "" },
        {
          text: "冷氣安裝",
          title: "冷氣安裝工程",
          subtotal: 0,
          description: "",
        },
        { text: "漏水", title: "漏水工程", subtotal: 0, description: "" },
      ],
    },
    {
      title: "設備工程",
      totalPrice: 0,
      items: [
        { text: "冷氣", title: "冷氣", subtotal: 0, description: "" },
        { text: "熱水器", title: "熱水器", subtotal: 0, description: "" },
        { text: "排風扇", title: "排風扇", subtotal: 0, description: "" },
      ],
    },
    { title: "其他工程", totalPrice: 0, description: "" },
    { title: "廢棄物清運", totalPrice: 0, description: "" },
    { title: "設計圖繪製", totalPrice: 0, description: "" },
    { title: "運費", totalPrice: 0, description: "" },
  ];
  hasItems = ["場地工程", "工種工程", "設備工程"];

  //單一工項的資料
  panelData: any = [];

  //所有工項的資料
  allPanelData: any = [];

  repairOrderId: any;
  res: any;
  $bvModal: any;

  //是否是一般訂單 (true:一般訂單;flase:大金額訂單)
  orderFlag = false;
  firstQuote = false;
  checkAll = false;

  //hook
  created(): void {
    this.repairOrderId = this.$route.query.repairOrderId;
    this.confirmStatus();
    const allPanelData = localStorage.getItem("allPanelData");
    const expectElapsedDay = localStorage.getItem("expectElapsedDay");
    const expectElapsedTime = localStorage.getItem("expectElapsedTime");
    if (allPanelData) {
      this.allPanelData = JSON.parse(allPanelData);
      this.clickTitles(this.allPanelData[0].title);
    } else {
      this.allPanelData = [];
      this.clickTitles("假設工程");
    }
    expectElapsedDay ? (this.expectElapsedDay = expectElapsedDay) : "";
    expectElapsedTime ? (this.expectElapsedTime = expectElapsedTime) : "";
    this.calculatePrice();
    this.firstQuotation();
  }

  //computed
  get subtotal(): any {
    if (this.unitPrice != "" && this.quantity != "") {
      const subtotal: number = this.data.unitPrice * this.data.quantity;
      return this.addComma(subtotal);
    }
    return 0;
  }

  //報價項目金額總計
  get masterIncome(): number {
    let price = 0;
    this.lists.forEach((data) => {
      price += data.totalPrice;
    });
    return price;
  }

  get finallPrice(): number {
    return Math.round(Math.round(this.masterIncome * 1.15) * 1.05);
  }

  //method

  //判斷是否可以新增報價單
  async confirmStatus(): Promise<void> {
    try {
      const res = await DI.get("App").repairOrder.get(this.repairOrderId);
      const status = res.status;
      const orderAdditionalFlag = res.orderAdditionalFlag;
      const statusJudge = status < 40 || status > 50;
      const flagJudge =
        (orderAdditionalFlag & 8) == 0 && (orderAdditionalFlag & 32) == 0;

      if (statusJudge && flagJudge) {
        this.isLoading = false;
      } else {
        this.$router.push(
          `/Quotation/Info?repairOrderId=${this.repairOrderId}`
        );
      }
      this.censorOrder(orderAdditionalFlag);
    } catch (e) {
      console.log("error", e);
    }
  }

  //判斷是否是大訂單 ->true:簡易報價/false:大金額報價
  censorOrder(orderAdditionalFlag: number): void {
    if ((orderAdditionalFlag & 4) == 0) {
      this.orderFlag = true;
    }
  }

  //讀檔
  async getLoadData(): Promise<void> {  
    if (!this.openReadBox) {
      this.openReadBox = true;
      this.btnDisableRead = true;
      this.isLoadingRead = true;
      let res: any;
      try {
        res = await DI.get("App").quotation.listTemp(this.$store.state.user.id);
        this.readAllData = res.data;
        this.readSubmittedData = this.readAllData.filter((item: any) => {
          return item["status"] != -3;
        });
        this.readTempData = this.readAllData.filter((item: any) => {
          return item["status"] == -3;
        });
      } catch (e) {
        console.log("error", e);
      } finally {
        this.isLoadingRead = false;
        this.btnDisableRead = false;
      }
    } else {
      this.openReadBox = false;
      this.btnDisableRead = false;      
    }
   
  }

  //讀檔資料
  clickLoadData(): void {
    this.expectElapsedDay = "";
    this.expectElapsedTime = "";
    this.delListPrice();
    this.panelData = [];
    const quotation = this.readAllData.filter((item: any) => {
      return item["id"] == this.selectedRead;
    });

    const quotationString = JSON.parse(JSON.stringify(quotation));
    this.expectElapsedDay = quotationString[0].expectElapsedDay;
    this.expectElapsedTime = quotationString[0].expectElapsedTime;

    const quotData = quotationString[0].masterOfferedDetail;
    quotData.forEach((datas: any) => {
      datas["renderInput"] = 0;
      datas.items.forEach((data: any) => {
        data["showError"] = {
          name: false,
          unit: false,
          quantity: false,
          unitPrice: false,
        };
        data["showStates"] = 2;
        data["checkOk"] = true;
      });
    });

    this.allPanelData = quotData;
    this.calculatePrice();
    this.clickTitles(this.allPanelData[0].title);
    this.syncList();
    this.openReadBox = false;
    this.btnDisableRead = false;
    this.$bvModal.hide("load-modal");
  }

  //匯入資料時，計算項目列表金額
  calculatePrice() {
    const listSubArray: { title: any; subtotal: any }[] = [];
    this.allPanelData.forEach((data: any) => {
      listSubArray.push({ title: data.title, subtotal: data.subtotal });

      this.lists.forEach((list) => {
        //有小項目的工程
        if (list.items) {
          list.items.forEach((item) => {
            if (item.title == data.title) {
              item.subtotal = data.subtotal;
              item.description = data.description;
            }
          });
        } else {
          //沒有小項目的工程
          if (list.title == data.title) {
            list.totalPrice = data.subtotal;
            list.description = data.description;
          }
        }
      });
    });

    //工程列表 金額加總(有子項的工程加總金額)
    this.calculateTotalPrice();
  }

  //判斷是否是第一筆報價單
  async firstQuotation() {
    try {
      const res = await DI.get("App").quotation.list({
        repairOrderId: this.repairOrderId,
      });
      let notFirst: boolean;

      if (res.data == undefined || res.data == "") {
        this.firstQuote = true;
      } else {
        notFirst = res.data.some((item: { status: number }) => {
          return item.status == 1;
        });
        if (notFirst) {
          this.firstQuote = false;
        } else {
          this.firstQuote = true;
        }
      }
    } catch (e) {
      this.firstQuote = true;
      console.log("error", e);
    }
  }

  //存檔前先判斷是否超過5萬
  onSaveDraftPreview(): void {
    if (!this.firstQuote) {
      if (this.orderFlag && this.masterIncome >= 50000) {
        Swal.fire({
          title: "追加報價不可超過5萬",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$bvModal.show(`save-fileName`);
      }
    } else {
      this.$bvModal.show(`save-fileName`);
    }
  }

  //存檔
  async clickSave(fileName: any): Promise<void> {
    if (fileName) {
      this.isLoadingSave = true;
      const allPanelData = [...this.allPanelData];
      const quotData = JSON.parse(JSON.stringify(allPanelData));

      quotData.forEach((datas: any) => {
        delete datas.renderInput;
        datas.items.forEach((data: any) => {
          delete data.showError;
          delete data.showStates;
          delete data.checkOk;
        });
      });
      const data = {
        quotationGroup: quotData,
        expectElapsedTime: this.expectElapsedTime,
        expectElapsedDay: this.expectElapsedDay,
        isDraft: true,
        fileName: fileName,
      };

      if (this.firstQuote) {
        try {
          await DI.get("App").quotation.create(this.repairOrderId, data);
          this.$bvModal.hide("save-fileName");
          this.$bvModal.show(`save-modal`);
          this.fileName = "";
        } catch (e) {
          console.log("error", e);
          Swal.fire({
            title: "無法送出報價單",
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          this.isLoadingSave = false;
        }
      } else {
        try {
          await DI.get("App").quotation.append(
            this.repairOrderId,
            data,
            !this.orderFlag
          );
          this.$bvModal.hide("save-fileName");
          this.$bvModal.show(`save-modal`);
          this.fileName = "";
        } catch (e) {
          console.log("error", e);
          Swal.fire({
            title: "無法送出報價單",
            showConfirmButton: false,
            timer: 1500,
          });
        } finally {
          this.isLoadingSave = false;
        }
      }
    } else {
      Swal.fire({
        title: "請填寫報價名稱",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  //箭頭方向
  changeVector(data: any, index: number): void {
    const indexFalse = this.vectorArray.indexOf(false);
    if (indexFalse != index) this.vectorArray[indexFalse] = true;

    if (data) {
      this.vectorCount++;
      this.vectorArray[index] = !this.vectorArray[index];
    }
  }

  //新增同一筆的item
  addNewItem(index: number): void {
    this.panelData.items.push({
      name: "",
      unit: "",
      note: "",
      quantity: "",
      unitPrice: "",
      itemTotalPrice: 0,
      showStates: 1,
      showError: {
        name: false,
        unit: false,
        quantity: false,
        unitPrice: false,
      },
      checkOk: false,
    });
  }

  //新增一筆工項(新增前先確認狀態)
  bClickTitles(title: string): void {
    if (title != "場地工程" && title != "設備工程" && title != "工種工程") {
      const checkShowStatus = this.panelData.items.some(
        (item: { showStates: number }) => {
          return item.showStates == 1;
        }
      );
      const item = this.panelData.items[0];
      let checkItem = false;
      if (
        item.name != "" ||
        item.note != "" ||
        item.quantity != "" ||
        item.unitPrice != "" ||
        item.unit != ""
      ) {
        checkItem = true;
      }

      if (checkItem && checkShowStatus) {
        Swal.fire({
          title: "請先按確認鍵",
          showConfirmButton: false,
          timer: 1500,
        });
      } else if (!checkItem) {
        this.lists.forEach((list) => {
          //有小項目的工程
          if (list.items) {
            list.items.forEach((item) => {
              if (item.title == this.panelData.title) {
                item.description = "";
              }
            });
          } else {
            //沒有小項目的工程
            if (list.title == this.panelData.title) {
              list.description = "";
            }
          }
        });
        this.panelData = [];
        this.clickTitles(title);
      } else {
        this.panelData = [];
        this.clickTitles(title);
      }
    }
  }

  clickTitles(title: string): void {
    this.panelData = "";
    this.description = "";
    if (title != "場地工程" && title != "設備工程" && title != "工種工程") {
      const filterData = this.allPanelData.filter((item: { title: string }) => {
        return item.title == title;
      });

      //若allPanelData有一樣的工項時
      if (filterData.length > 0) {
        this.panelData = filterData[0];
      } else {
        //若allPanelData沒有一樣的工項時
        this.panelData = {
          title: title,
          subtotal: 0,
          renderInput: 0,
          description: "",
          items: [
            {
              name: "",
              unit: "",
              note: "",
              quantity: "",
              unitPrice: "",
              itemTotalPrice: 0,
              showStates: 1,
              showError: {
                name: false,
                unit: false,
                quantity: false,
                unitPrice: false,
              },
              checkOk: false,
            },
          ],
        };
      }
    }
  }

  //工期天數
  onCheckSession(): void {
    if (this.expectElapsedDay == "") {
      this.checkSession[0] = true;
      this.checkSessionWord = true;
    } else {
      if (this.expectElapsedTime == "") {
        this.expectElapsedTime = "0";
      }
      this.checkSession[0] = false;
      this.checkSessionWord = false;
    }

    if (this.expectElapsedTime == "") {
      this.checkSession[1] = true;
      this.checkSessionWord = true;
    } else {
      this.checkSession[1] = false;
      this.checkSessionWord = false;
    }

    if (this.expectElapsedDay == "0" && this.expectElapsedTime == "0") {
      this.checkSession = [true, true];
      this.checkSessionWord = true;
    }
  }
  showInputDes(title: string, showStates: number) {
    if (showStates == 1) {
      this.$bvModal.show(`input-des-${title}`);
    } else {
      this.panelData.items[0].showStates = 1;
    }
  }

  //輸入描述
  clickInputDes(title: any): void {
    this.panelData.description = this.description;
    this.syncList();
    this.description = "";
    this.$bvModal.hide(`input-des-${title}`);
  }

  getUnit(items: any): void {
    let rUnit = items[0];
    let rIndex = items[1];
    this.panelData.items[rIndex].unit = rUnit;
  }

  getInputData(items: any): void {
    let rVariable = items[0];
    let data = items[1];
    let rIndex = items[2];

    if (rVariable == "quantity") {
      this.panelData.items[rIndex].quantity = Number(data.trim());
      this.calculateItemPrice(rIndex);
    }
    if (rVariable == "unitPrice") {
      this.panelData.items[rIndex].unitPrice = Number(data.trim());
      this.calculateItemPrice(rIndex);
    }
    if (rVariable == "name") this.panelData.items[rIndex].name = data.trim();
    if (rVariable == "note") this.panelData.items[rIndex].note = data.trim();
  }

  calculateItemPrice(rIndex: number): void {
    this.panelData.items[rIndex].itemTotalPrice =
      this.panelData.items[rIndex].quantity *
      this.panelData.items[rIndex].unitPrice;
    this.calculateSubTotal();
  }

  calculateSubTotal(): void {
    let total = 0;
    this.panelData.items.forEach((element: any) => {
      total = total + element.itemTotalPrice;
    });
    this.panelData.subtotal = total;
    this.syncList();
    this.calculateTotalPrice();
  }

  //工程列表 金額加總(有子項的工程加總金額)
  calculateTotalPrice(): void {
    this.lists.forEach((el) => {
      if (
        el.title == "場地工程" ||
        el.title == "設備工程" ||
        el.title == "工種工程"
      ) {
        el.totalPrice = 0;
        el.items?.forEach((i) => {
          el.totalPrice += i.subtotal;
        });
      }
    });
  }

  //工程列表 (金額+描述) 賦值
  syncList(): void {
    this.lists.forEach((el) => {
      //大項目
      if (
        el.title == "假設工程" ||
        el.title == "拆除工程" ||
        el.title == "其他工程" ||
        el.title == "廢棄物清運" ||
        el.title == "運費" ||
        el.title == "設計圖繪製"
      ) {
        if (el.title === this.panelData.title) {
          el.totalPrice = this.panelData.subtotal;
          el.description = this.panelData.description;
        }
      } else {
        //小項目

        el.items?.forEach((i) => {
          if (i.title === this.panelData.title) {
            i.subtotal = this.panelData.subtotal;
            i.description = this.panelData.description;
          }
        });
      }
    });
  }

  //確認工項資料是否填寫完整
  onCheck(index: number): void {
    const itemList = this.panelData.items[index];
    const errorList = itemList.showError;

    itemList.name == "" ? (errorList.name = true) : (errorList.name = false);
    itemList.quantity == "" || itemList.quantity == 0
      ? (errorList.quantity = true)
      : (errorList.quantity = false);
    itemList.unitPrice == ""
      ? (errorList.unitPrice = true)
      : (errorList.unitPrice = false);
    itemList.unit == "" ? (errorList.unit = true) : (errorList.unit = false);

    const checkout = Object.values(errorList).every((item) => {
      return item == false;
    });

    if (checkout) {
      itemList.showStates = 2;
      itemList.checkOk = true;

      //篩選並放入allpanelData
      const panel = JSON.parse(JSON.stringify(this.panelData));
      panel.items = panel.items.filter((item: any) => {
        return item.checkOk == true;
      });

      const findIndex = this.allPanelData
        .map((item: { title: any }) => {
          return item.title;
        })
        .indexOf(panel.title);

      if (findIndex == -1) {
        this.allPanelData.push(panel);
      } else {
        this.allPanelData[findIndex] = panel;
      }
    } else {
      itemList.checkOk = false;
    }
  }

  //刪除所有工項
  delAllModal(): void {
    this.allPanelData = [];
    this.panelData = [];
    this.expectElapsedTime = "";
    this.expectElapsedDay = "";
    this.description = "";
    localStorage.removeItem("allPanelData");
    localStorage.removeItem("expectElapsedDay");
    localStorage.removeItem("expectElapsedTime");
    this.$bvModal.hide("delete-all-modal");
    this.delListPrice();
    this.clickTitles("假設工程");
  }

  //清空列表金額+描述
  delListPrice(): void {
    this.lists.forEach((list) => {
      list.totalPrice = 0;
      list.description = "";
      if (list.items) {
        list.items.forEach((item) => {
          item.subtotal = 0;
          item.description = "";
        });
      }
    });
  }

  //刪除單筆工項
  delData(itemIndex: number): void {
    this.panelData.items.splice(itemIndex, 1);
    if (this.panelData.items == 0) {
      this.panelData.items.push({
        name: "",
        unit: "",
        note: "",
        quantity: "",
        unitPrice: "",
        itemTotalPrice: 0,
        showStates: 1,
        showError: {
          name: false,
          unit: false,
          quantity: false,
          unitPrice: false,
        },
        checkOk: false,
      });
    }
    this.panelData.renderInput++;
    this.calculateSubTotal();

    //處理allPanelData
    const panel = JSON.parse(JSON.stringify(this.panelData));
    panel.items = panel.items.filter((item: any) => {
      return item.checkOk == true;
    });

    const findIndex = this.allPanelData
      .map((item: { title: any }) => {
        return item.title;
      })
      .indexOf(panel.title);

    if (findIndex > -1) {
      if (panel.items.length > 0) {
        this.allPanelData[findIndex] = panel;
      } else {
        this.allPanelData.splice(findIndex, 1);
      }
    }

    this.calculateTotalPrice();
    this.$bvModal.hide(`delete-item-modal-${itemIndex}`);
  }

  //離開
  clickLeave(): void {
    this.expectElapsedTime = "";
    this.expectElapsedDay = "";
    localStorage.removeItem("allPanelData");
    localStorage.removeItem("expectElapsedDay");
    localStorage.removeItem("expectElapsedTime");
    this.$bvModal.hide("return-modal");
    this.$router.push(`/Quotation/Info?repairOrderId=${this.repairOrderId}`);
  }

  getCheckAll() {
    //檢查所有資料allPanelData
    let checkArray: any[] = [];
    this.allPanelData.forEach((el: { items: any[] }) => {
      el.items.forEach((item) => {
        checkArray.push(item.checkOk);
      });
    });

    this.checkAll = checkArray.every((i) => i == true);
  }

  //檢查送出前的所有資料
  checkoutAllPanelData(btnState: string): void {
    let checkShowStatus: boolean;

    //檢查目前panelData
    const panelItem = this.panelData.items[0];
    if (
      panelItem.name != "" ||
      panelItem.quantity != "" ||
      panelItem.unitPrice != "" ||
      panelItem.unit != "" ||
      panelItem.note != "" ||
      this.panelData.description != ""
    ) {
      checkShowStatus = this.panelData.items.some(
        (item: { showStates: number }) => {
          return item.showStates == 1;
        }
      );
      if (checkShowStatus) {
        Swal.fire({
          title: "請先按確認鍵",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      checkShowStatus = false;
    }

    this.getCheckAll();

    if (!this.checkAll) {
      const panel = JSON.parse(JSON.stringify(this.allPanelData));
      panel.forEach((el: { items: any[] }) => {
        el.items = el.items.filter((item: any) => {
          return item.checkOk == true;
        });
      });
      this.allPanelData = panel;
      this.getCheckAll();
    }

    if (
      this.expectElapsedDay == "" ||
      this.expectElapsedTime == "" ||
      (this.expectElapsedDay == "0" && this.expectElapsedTime == "0")
    ) {
      Swal.fire({
        title: "請確認工期",
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (checkShowStatus) {
      Swal.fire({
        title: "請確認所有項目是否都有按「確定」鍵",
        showConfirmButton: false,
        timer: 2500,
      });
    } else if (!this.checkAll) {
      Swal.fire({
        title: "請確認所有資料是否已填寫",
        showConfirmButton: false,
        timer: 2500,
      });
    } else if (this.allPanelData.length == 0) {
      Swal.fire({
        title: "請輸入至少一個工項",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      if (btnState == "preview") {
        this.onSubmitPreview();
      } else {
        this.onSaveDraftPreview();
      }
    }
  }

  onSubmitPreview(): void {
    if (!this.firstQuote) {
      if (this.orderFlag && this.masterIncome >= 50000) {
        Swal.fire({
          title: "追加報價不可超過5萬",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.onSubmit();
      }
    } else {
      this.onSubmit();
    }
  }

  onSubmit(): void {
    const allPanelData = [...this.allPanelData];
    const quotData = JSON.parse(JSON.stringify(allPanelData));

    quotData.forEach((datas: any) => {
      delete datas.renderInput;
      datas.items.forEach((data: any) => {
        delete data.showError;
        delete data.showStates;
        delete data.checkOk;
      });
    });

    let platformServicePrice = 0 as number;

    if (this.masterIncome > 49999) {
      platformServicePrice = 5000;
    } else {
      platformServicePrice = Math.round(
        Math.round(Math.round(this.masterIncome * 1.15) * 1.05) * 0.15
      );
    }

    const quotation = {
      repairOrderId: this.repairOrderId,
      offerPrice: Math.round(this.masterIncome * 1.15),
      taxedOfferPrice: Math.round(Math.round(this.masterIncome * 1.15) * 1.05),
      masterIncomeOfferPrice: this.masterIncome,
      platformServicePrice: platformServicePrice,
      totalPrice:
        Math.round(Math.round(this.masterIncome * 1.15) * 1.05) +
        platformServicePrice,
      masterOfferedDetail: quotData,
      expectElapsedTime: this.expectElapsedTime,
      expectElapsedDay: this.expectElapsedDay,
      createDate: format(toDate(new Date()), "yyyy-MM-dd HH:mm"),
      createBy: this.$store.state.user.id,
      updateDate: format(toDate(new Date()), "yyyy-MM-dd HH:mm"),
      updateBy: this.$store.state.user.id,
    };
    this.$store.commit("setQuotation", JSON.parse(JSON.stringify(quotation)));
    localStorage.setItem("allPanelData", JSON.stringify(this.allPanelData));
    localStorage.setItem("expectElapsedDay", this.expectElapsedDay);
    localStorage.setItem("expectElapsedTime", this.expectElapsedTime);

    this.$router.push(`/Quotation/Pdf?repairOrderId=${this.repairOrderId}`);
  }

  //watch
  @Watch("expectElapsedDay")
  watchSessionDay(): void {
    this.onCheckSession();
  }
  @Watch("expectElapsedTime")
  watchSessionTime(): void {
    this.onCheckSession();
  }
}
