
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class FormSelect extends Vue {
  @Prop() options!: [];
  @Prop({ default: false }) isDisable!: boolean; //是否隱藏
  @Prop({ default: false }) reset!: boolean; //重製選擇框
  @Prop({ default: "請選擇" }) placeholder!: string;
  @Prop() selectProp!: string;
  @Prop() index?: number;

  showOption = false;
  item = "";
  isBlack = false;

  mounted(): void {
    if (this.selectProp) {
      this.item = this.selectProp;
      this.isBlack = true;
    } else {
      this.item = this.placeholder;
    }
  }

  toggleOption(): void {
    this.showOption = !this.showOption;
  }

  clickOption(option: any): void {
    this.item = option.text;
    this.showOption = false;
    this.isBlack = true;
    this.$emit("selectOption", [option.text,this.index]);
  }

  //Watch
  @Watch("reset")
  resetHandler() {
    this.item = this.placeholder;
    this.isBlack = false;
  }
}
